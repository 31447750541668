<script setup lang="ts">
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { ROUTES } from "@/router/route-consts";

onMounted(() => {
    const router = useRouter();
    if (window === parent) {
        console.log("Change route close-session");
        router.push({ path: ROUTES.HALLS.path }).then();
    } else {
        console.log("Post message close-session");
        parent.postMessage("close-session", "*");
    }
});
</script>

<template>
    <div>Close session</div>
</template>

<style scoped>

</style>
