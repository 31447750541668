import { onMounted, onUnmounted, ref } from "vue";
import HeaderMain from "@/components/header/HeaderMain.vue";
import FooterMain from "@/components/footer/FooterMain.vue";
import { useBalanceStore } from "@/store/balance-store";
import { useRestService } from "@/services";
import { useUserStore } from "@/store/user-store";
import Cookies from "js-cookie";
import { SESSION_COOKIE } from "@/consts/cookies-consts";
import { useRouter } from "vue-router";
import { ROUTES } from "@/router/route-consts";

export default {
    name: "MainView",
    components: { FooterMain, HeaderMain },
    setup () {
        const isInitialized = ref(false);
        let mounted = true;
        const balanceStore = useBalanceStore();
        const restService = useRestService();
        const store = useUserStore();
        const router = useRouter();

        onMounted(async () => {
            const storedSession = Cookies.get(SESSION_COOKIE);
            if (!store.isLoggedIn && storedSession) {
                restService.session = storedSession;
                store.user = await restService.getUser();
            }
            async function updateBalance () {
                if (mounted) {
                    const balance = await restService.getExtendedBalance();
                    balanceStore.balance = balance;
                    setTimeout(updateBalance, 5000);
                }
            }
            setTimeout(updateBalance);
            isInitialized.value = true;
            window.addEventListener("message", (event) => {
                if (event.data === "close-session") {
                    console.log("Change route!");
                    router.push({ name: ROUTES.HALLS.name });
                }
            });
        });

        onUnmounted(() => {
            mounted = false;
        });
        return {
            isInitialized
        };
    }
};
