import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useUserStore } from "@/store/user-store";
import { ROUTES } from "@/router/route-consts";
import { useRoute, useRouter } from "vue-router";
import { useBalanceStore } from "@/store/balance-store";
import VoucherDepositDialog
    from "@/views/deposit-view/voucher-deposit-dialog/VoucherDepositDialog.vue";
import Cookies from "js-cookie";
import { SESSION_COOKIE } from "@/consts/cookies-consts";

export default {
    name: "HeaderMain",
    components: {
        VoucherDepositDialog
    },
    setup () {
        const showMenu = ref(false);
        const mobMenuShow = ref(false);
        const router = useRouter();
        const userStore = useUserStore();
        const balanceStore = useBalanceStore();
        const route = useRoute();

        watch(() => route.name, () => {
            showMenu.value = false;
            mobMenuShow.value = false;
        });
        const userNumber = computed(() => {
            const user = userStore.user;
            if (user) {
                return user.phone ? user.phone : user.userNumber;
            }
            return "";
        });

        const balance = computed(() => {
            const currencyMark = balanceStore.balance?.mainBalance?.primaryAccount?.currencyMark ?? "";
            return balanceStore.balanceTotal + currencyMark;
        });
        function logout () {
            console.log("Logout");
            userStore.user = undefined;
            Cookies.remove(SESSION_COOKIE);
            router.push({ name: ROUTES.LOGIN.name }).then();
        }

        return {
            balance,
            userNumber,
            mobMenuShow,
            showMenu,
            ROUTES,
            logout
        };
    }
};
